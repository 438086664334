import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { deleteUnderwriterById, getAllUnderwritersTable, regenerateUnderwriterSecretKey } from 'shared/apis';

import EditRHF from 'components/Admin/UnderwritersTable/EditRHF';
import NewRHF from 'components/Admin/UnderwritersTable/NewRHF';
import ModalConfirm from 'components/UI/ModalConfirm';
import { TableQuery } from 'components/UI/TableQuery';

import getColumns from './columns';

const UnderwritersTable = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [modalVariant, setModalVariant] = useState('danger');
  const [modalTitle, setModalTitle] = useState('');
  const [modalProceedLabel, setModalProceedLabel] = useState('');
  const [modalOnProceed, setModalOnProceed] = useState(() => () => {});
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedUnderwriter, setSelectedUnderwriter] = useState({});
  const [showCreateModal, setShowCreateModal] = useState(false);

  const {
    mutate: mutateRegenerateSecretKey,
    isPending: isRegenerateSecretKeyLoading,
    isError: isRegenerateSecretKeyError,
  } = useMutation({
    mutationFn: regenerateUnderwriterSecretKey,
    onSuccess: (data) => {
      alert('Secret key: ' + data);
      queryClient.invalidateQueries('getAllUnderwritersTable');
    },
  });

  const {
    mutate: mutateDeleteUnderwriter,
    isPending: isDeleteUnderwriterLoading,
    isError: isDeleteUnderwriterError,
  } = useMutation({
    mutationFn: deleteUnderwriterById,
    onSuccess: () => {
      queryClient.invalidateQueries('getAllUnderwritersTable');
    },
  });

  const regenerateSecretKey = (row) => {
    setModalTitle(t('screens.underwriters.table.modals.regenerateSecretKey.title'));
    setModalContent(`${t('screens.underwriters.table.modals.regenerateSecretKey.areYouSure')} ${row.name}?`);
    setModalVariant('warning');
    setModalProceedLabel(t('screens.underwriters.table.modals.regenerateSecretKey.proceed'));
    setModalOnProceed(() => () => {
      mutateRegenerateSecretKey(row._id);
      setShowModal(false);
    });
    setShowModal(true);
  };

  const deleteUnderwriter = (row) => {
    setModalTitle(t('screens.underwriters.table.modals.deleteUnderwriter.title'));
    setModalContent(`${t('screens.underwriters.table.modals.deleteUnderwriter.areYouSure')} ${row.name}?`);
    setModalVariant('danger');
    setModalProceedLabel(t('screens.underwriters.table.modals.deleteUnderwriter.proceed'));
    setModalOnProceed(() => () => {
      mutateDeleteUnderwriter(row._id);
      setShowModal(false);
    });
    setShowModal(true);
  };

  const editUnderwriter = (row) => {
    setSelectedUnderwriter(row);
    setShowEditModal(true);
  };

  const Columns = getColumns(regenerateSecretKey, deleteUnderwriter, editUnderwriter);

  const handleEditModalClose = () => {
    setShowEditModal(false);
    setSelectedUnderwriter({});
    queryClient.invalidateQueries('getAllUnderwritersTable');
  };

  const handleCreateModalClose = () => {
    setShowCreateModal(false);
    queryClient.invalidateQueries('getAllUnderwritersTable');
  };

  return (
    <>
      <TableQuery
        queryKey={'getAllUnderwritersTable'}
        queryFn={getAllUnderwritersTable}
        Columns={Columns}
        columnTPrefix={'screens.underwriters.table.columns.'}
        defaultSort={'name'}
        actions={[
          {
            type: 'button',
            label: t('screens.underwriters.actions.create'),
            variant: 'success',
            onClick: () => {
              setShowCreateModal(true);
            },
          },
        ]}
      />
      <ModalConfirm
        show={showModal}
        onClose={() => setShowModal(false)}
        onProceed={() => modalOnProceed()}
        title={modalTitle}
        proceedLabel={modalProceedLabel}
        variant={modalVariant}
        content={modalContent}
        proceed={modalProceedLabel}
        isLoading={isRegenerateSecretKeyLoading || isDeleteUnderwriterLoading}
        isError={isRegenerateSecretKeyError || isDeleteUnderwriterError}
      />
      {showEditModal && <EditRHF show={showEditModal} onClose={handleEditModalClose} selectedUnderwriter={selectedUnderwriter} />}
      {showCreateModal && <NewRHF show={showCreateModal} onClose={handleCreateModalClose} />}
    </>
  );
};

export default UnderwritersTable;
