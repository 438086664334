import { ERROR_CODES } from 'errors/components/auctions/auctions-table';
import some from 'lodash/some';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { AuthContext } from 'context/Auth';

import { getActiveAuctionsTable } from 'shared/apis';
import { getErrorNameFromErrorResponse } from 'shared/utils/error';
import { errorAlertBuilder } from 'shared/utils/errorAlertBuilder';

import getColumns from 'components/AuctionsTable/columns';
import { TableQuery } from 'components/UI/TableQuery';

export const AuctionsTable = () => {
  const { activeContracts, canCreateOffers } = useContext(AuthContext);

  const { t } = useTranslation();

  const getErrorAlertContent = (error) => {
    const errorName = getErrorNameFromErrorResponse(error);
    switch (errorName) {
      case ERROR_CODES.INVALID_FILTERS:
        return errorAlertBuilder.lookupInvalidFilters(error);
      default:
        return null;
    }
  };

  return (
    <TableQuery
      queryKey="active-auctions-lookup"
      queryFn={getActiveAuctionsTable}
      getErrorAlertContent={getErrorAlertContent}
      Columns={getColumns({ t, showActionCell: canCreateOffers() })}
      columnTPrefix="screens.dashboard.table.columns."
      defaultSort="issuedCompanyName"
      title={t('screens.dashboard.titles.activeTenders')}
      withPagination={false}
      withSearch={false}
      enabled={!!some(activeContracts)}
    />
  );
};
