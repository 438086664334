import { Domain } from './const/Domain';

export const buildTableUrl = (route, options) => {
  const { searchKey, page, limit, sortBy, sortDirection, filters } = options;
  let url = `${Domain}${route}?searchKey=${searchKey}&page=${page}&limit=${limit}&sortBy=${sortBy}&sortDirection=${sortDirection}`;
  const encodedFilters = encodeURIComponent(JSON.stringify(filters || {}));
  url += `&UriEncodedFilters=${encodedFilters}`;
  if (options.project) {
    url += `&project=${options.project.join(',')}`;
  }
  return url;
};
