import { faExternalLink } from '@fortawesome/free-solid-svg-icons';
import compact from 'lodash/compact';

import { DateCell, LinkCell, NoWrapCell } from 'components/UI/TableQuery';

export const columns = (isExtended) => {
  const extended = [
    {
      Header: 'issuingCompanyAndSecurity',
      accessor: 'issuingCompany',
      Cell: (row) => NoWrapCell(`${row.issuingCompany} - ${row.issuedSecurity}`),
    },
    {
      Header: 'maalotRating',
      accessor: 'maalotRating',
    },
    {
      Header: 'midroogRating',
      accessor: 'midroogRating',
    },
    {
      Header: 'linkage',
      accessor: 'linkage',
    },
    {
      Header: 'duration',
      accessor: 'duration',
    },
    {
      Header: 'commitmentFee',
      accessor: 'commitmentFee',
    },
    {
      Header: 'leadingUnderwriters',
      accessor: 'leadingUnderwriters',
      Cell: (row) => NoWrapCell(row.leadingUnderwriters),
    },
    {
      Header: 'distributionPageLink',
      accessor: 'distributionPageLink',
      Cell: (row) => LinkCell(row.distributionPageLink, faExternalLink),
    },
    {
      Header: 'companyPresentationLink',
      accessor: 'companyPresentationLink',
      Cell: (row) => LinkCell(row.companyPresentationLink, faExternalLink),
    },
    {
      Header: 'prospectusLink',
      accessor: 'prospectusLink',
      Cell: (row) => LinkCell(row.prospectusLink, faExternalLink),
    },
    {
      Header: 'trustDeedLink',
      accessor: 'trustDeedLink',
      Cell: (row) => LinkCell(row.trustDeedLink, faExternalLink),
    },
    {
      Header: 'previousIssuesResults',
      accessor: 'previousIssuesResults',
      Cell: (row) => LinkCell(row.previousIssuesResults, faExternalLink),
    },
    {
      Header: 'institutionalHolders',
      accessor: 'institutionalHolders',
      Cell: (row) => LinkCell(row.institutionalHolders, faExternalLink),
    },
    {
      Header: 'institutionalAuctionDate',
      accessor: 'institutionalAuctionDate',
      Cell: (row) => DateCell(row.institutionalAuctionDate),
    },
    {
      Header: 'lastUpdateDate',
      accessor: 'lastUpdateDate',
      Cell: (row) => DateCell(row.lastUpdateDate),
    },
  ];

  const base = extended.filter((col) => !['issuerNumber', 'securityNumber'].includes(col.accessor));

  return compact(isExtended ? extended : base);
};
