import { useCallback, useContext } from 'react';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';

import { AuthContext } from 'context/Auth';
import { CompanyDetailsContext } from 'context/CompanyDetails';

import { contractRoles } from 'shared/const/contractRoles';
import { systemRoles } from 'shared/const/systemRoles';

export const ViewModeCompanyTitle = ({ className, ...props }) => {
  const { t } = useTranslation();
  const { toggleMode, companyDetails, role } = useContext(CompanyDetailsContext);
  const { systemRole } = useContext(AuthContext);

  const Actions = useCallback(() => {
    const isUserAllowedToEdit = role === contractRoles.COMPANY_ADMIN || systemRole === systemRoles.SB_ADMIN;

    if (!isUserAllowedToEdit) {
      return null;
    }

    return (
      <Button variant="primary" onClick={toggleMode}>
        {t('screens.company.actions.edit')}
      </Button>
    );
  }, [role, systemRole, toggleMode, t]);

  return (
    <div className={`flex justify-between ${className}`} {...props}>
      <h1>{companyDetails.name}</h1>
      <Actions />
    </div>
  );
};
