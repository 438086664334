import { useState } from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import CompaniesTable from 'components/Admin/CompaniesTable/table';

const CompaniesScreen = () => {
  const { t } = useTranslation();
  const [companiesCount, setCompaniesCount] = useState(0);

  return (
    <Container className="mb-5 mt-4">
      <h1>
        {t('screens.companies.titles.companiesTable')} ({companiesCount})
      </h1>
      <CompaniesTable companiesCount={companiesCount} setCompaniesCount={setCompaniesCount} />
    </Container>
  );
};

export default CompaniesScreen;
