import isEmpty from 'lodash/isEmpty';
import React from 'react';

import { contractStatues } from 'shared/const/contractStatues';

import ContractItem from 'components/User/Dashboard/ContractsRow/ContractItem';

import * as S from './styles';

const MySignedContracts = ({ contractsByStatus }) => {
  if (isEmpty(contractsByStatus[contractStatues.ACTIVE]) && isEmpty(contractsByStatus[contractStatues.WAITING_FOR_COMPANY_APPROVAL])) {
    return null;
  }
  return (
    <S.ContractRow>
      {contractsByStatus[contractStatues.ACTIVE]?.map((contract, index) => (
        <ContractItem contract={contract} key={index} />
      ))}
      {contractsByStatus[contractStatues.WAITING_FOR_COMPANY_APPROVAL]?.map((contract, index) => (
        <ContractItem contract={contract} key={index} />
      ))}
    </S.ContractRow>
  );
};

export default MySignedContracts;
