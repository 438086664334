import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { getAllUsersTable } from 'shared/apis';

import { TableQuery } from 'components/UI/TableQuery';

import Columns from './columns';

const UsersTable = ({ userCount, setUserCount }) => {
  const queryClient = useQueryClient();
  const queryCache = queryClient.getQueryCache();

  useEffect(() => {
    // listen to changes in the query cache for the usersLookup query, and update the count accordingly
    queryCache.subscribe(({ query }) => {
      if (query.state.status !== 'success' || query.queryKey[0] !== 'getAllUsersTable') return;

      const count = query.state.data?.count;

      if (!count || count === 0) return;

      if (userCount === count) return;

      setUserCount(count);
    });
  }, [queryCache, userCount, setUserCount]);

  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <TableQuery
      queryKey={'getAllUsersTable'}
      queryFn={getAllUsersTable}
      Columns={Columns}
      columnTPrefix={'screens.users.table.columns.'}
      defaultSort={'firstName'}
      withExport={true}
      actions={[
        {
          type: 'button',
          label: t('screens.users.actions.create'),
          variant: 'success',
          onClick: () => {
            navigate('/admin/users/new');
          },
        },
      ]}
    />
  );
};

export default UsersTable;
