import { ERROR_CODES } from 'errors/components/companies/users-table';
import React, { useContext } from 'react';

import { AuthContext } from 'context/Auth';
import { CompanyDetailsContext } from 'context/CompanyDetails';

import { getCompanyUsersTable } from 'shared/apis';
import { contractRoles } from 'shared/const/contractRoles';
import { getErrorNameFromErrorResponse } from 'shared/utils/error';

import { TableQuery } from 'components/UI/TableQuery';

import Columns from './columns';

export const CompanyUsersTable = () => {
  const { companyId, role } = useContext(CompanyDetailsContext);
  const { isSBAdmin } = useContext(AuthContext);

  if (!companyId) return null;

  const isSbOrCompanyAdmin = role === contractRoles.COMPANY_ADMIN || isSBAdmin;

  const getErrorAlertContent = (error) => {
    const errorName = getErrorNameFromErrorResponse(error);
    switch (errorName) {
      case ERROR_CODES.COMPANY_NOT_FOUND:
        return {
          title: 'components.CompanyDetails.UsersTable.errors.companyNotFound.title',
          description: 'components.CompanyDetails.UsersTable.errors.companyNotFound.description',
        };
      default:
        return null;
    }
  };

  return (
    <TableQuery
      queryKey={'getCompanyUsersTable'}
      queryParams={{ companyId }}
      queryFn={(options) => getCompanyUsersTable(options, companyId)}
      getErrorAlertContent={getErrorAlertContent}
      Columns={Columns(isSbOrCompanyAdmin)}
      columnTPrefix={'screens.company.table.columns.'}
      defaultSort={'firstName'}
      style={{ backgroundColor: 'white' }}
      withSearch={false}
      withPagination={false}
      withSort={false}
      withExport={true}
    />
  );
};
