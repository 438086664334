import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { getAllCompaniesTable } from 'shared/apis';

import { TableQuery } from 'components/UI/TableQuery';

import Columns from './columns';

const CompaniesTable = ({ companiesCount, setCompaniesCount }) => {
  const queryClient = useQueryClient();
  const queryCache = queryClient.getQueryCache();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    // listen to changes in the query cache for the companiesLookup query, and update the count accordingly
    queryCache.subscribe(({ query }) => {
      if (query.state.status !== 'success' || query.queryKey[0] !== 'getAllCompaniesTable') return;

      const count = query.state.data?.count;

      if (!count || count === 0) return;

      if (companiesCount === count) return;

      setCompaniesCount(count);
    });
  }, [queryCache, companiesCount, setCompaniesCount]);

  return (
    <TableQuery
      queryKey={'getAllCompaniesTable'}
      queryFn={getAllCompaniesTable}
      Columns={Columns({ t })}
      columnTPrefix={'screens.companies.table.columns.'}
      defaultSort={'name'}
      actions={[
        {
          type: 'button',
          label: t('screens.companies.actions.create'),
          variant: 'success',
          onClick: () => {
            navigate('/admin/companies/new');
          },
        },
      ]}
      withExport={true}
    />
  );
};

export default CompaniesTable;
