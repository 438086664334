import React, { useContext } from 'react';

import { UserDetailsContext } from 'context/UserDetails';

import { getUserRoles } from 'shared/apis';

import { TableQuery } from 'components/UI/TableQuery';

import Columns from './columns';

const UserRolesTable = () => {
  const { userId } = useContext(UserDetailsContext);

  if (!userId) return null;

  return (
    <TableQuery
      queryKey={'getUserRoles'}
      queryKeyParams={[userId]}
      queryFn={(options) => getUserRoles(options, userId)}
      Columns={Columns}
      columnTPrefix={'screens.user.table.columns.'}
      defaultSort={'companyName'}
      style={{ backgroundColor: 'white' }}
      withSearch={false}
      withPagination={false}
      withSort={false}
    />
  );
};

export default UserRolesTable;
