import { Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { PublicOfferingsTable } from 'components/PublicOfferingsTable';
import Box from 'components/UI/Box';

export const PublicOfferingsScreen = () => {
  const { t } = useTranslation();
  return (
    <Container className="mt-5">
      <Row>
        <h1 className="text-center">{t('screens.publicOfferings.texts.title')}</h1>
      </Row>
      <Row className="mt-5">
        <Box>
          <PublicOfferingsTable scrollable withPagination />
        </Box>
      </Row>
    </Container>
  );
};
