import PhoneNumberUtil from 'libphonenumber-js';
import _ from 'lodash';
import { Link } from 'react-router-dom';

import { isStringADate } from 'shared/utils/isStringADate';

const extractDateFromRow = (row, accessor) => {
  const value = _.get(row, accessor);
  if (!isStringADate(value)) return '';
  const date = new Date(value);
  const result = date.toLocaleString();
  return result;
};

const Columns = [
  { Header: 'employeePersonalId', accessor: 'employee.personalId', sortKey: 'employee.personalId' },
  {
    Header: 'employeeFirstName',
    accessor: 'employee.firstName',
  },
  {
    Header: 'employeeLastName',
    accessor: 'employee.lastName',
  },
  {
    Header: 'employeeEmail',
    accessor: 'employee.email',
    Cell: (row) => {
      return <Link to={`/admin/users/${row.employee.userId}`}>{row.employee.email}</Link>;
    },

    exportAccessor: 'employeeEmail',
  },
  {
    Header: 'employeeMobile',
    accessor: 'employee.mobile',
    Cell: (row) => {
      const result = PhoneNumberUtil(row.employee.mobile).formatNational();
      return result;
    },
  },
  {
    Header: 'companyName',
    accessor: 'company.name',
  },
  {
    Header: 'companyBnNumber',
    accessor: 'company.bnNumber',
  },
  {
    Header: 'role',
    accessor: 'role',
  },
];

export const pendingColumns = [
  ...Columns,
  {
    Header: 'submissionDate',
    accessor: 'creationAction.date',
    Cell: (row) => {
      return extractDateFromRow(row, 'creationAction.date');
    },
  },
];

export const approvedColumns = [
  ...Columns,
  {
    Header: 'approvedBy',
    accessor: 'statusChangeBy',
  },
  {
    Header: 'approvedAt',
    accessor: 'statusChangeAt',
    Cell: (row) => {
      return extractDateFromRow(row, 'statusChangeAt');
    },
  },
];

export const rejectedColumns = [
  ...Columns,
  {
    Header: 'rejectedBy',
    accessor: 'statusChangeBy',
  },
  {
    Header: 'rejectedAt',
    accessor: 'statusChangeAt',
    Cell: (row) => {
      return extractDateFromRow(row, 'statusChangeAt');
    },
  },
];
