import axios from 'axios';

import { GAEventNames, GAEventStages } from 'shared/const/googleAnalytics';

import { sendGAEvent } from 'hooks/useGoogleAnalytics';

import { buildTableUrl } from './apiUtils';
import { Domain } from './const/Domain';

//todo: look for unused functions and clean
export const getAllUsersTable = async (options) => {
  const url = buildTableUrl('/user/user-lookup', options);
  const data = await axios.get(url);
  const result = {
    count: data?.data?.count,
    data: data?.data?.users,
  };
  return result;
};

export const getAllCompaniesTable = async (options) => {
  const url = buildTableUrl('/company/company-lookup', options);
  const data = await axios.get(url);
  const result = {
    count: data?.data?.count,
    data: data?.data?.companies,
  };
  return result;
};

export const getCompanyUsersTable = async (options, selectedCompany) => {
  const url = buildTableUrl(`/company/${selectedCompany}/employees`, options);
  const data = await axios.get(url);
  const result = {
    count: data?.data?.count,
    data: data?.data?.users,
  };
  return result;
};

export const getCompanyBankAccountsTable = async (options, companyId) => {
  const url = buildTableUrl(`/company/${companyId}/bank-accounts`, options);
  const data = await axios.get(url);
  const result = {
    count: data?.data?.count,
    data: data?.data?.bankAccounts,
  };
  return result;
};

export const getContractsTable = async (lookupPath, options) => {
  options.filters = options?.queryParams;
  const url = buildTableUrl(`/contract/lookup/${lookupPath}`, options);
  const data = await axios.get(url);
  const result = {
    count: data?.data?.count,
    data: data?.data?.contracts,
  };
  return result;
};

export const contractsLookupForSbAdmin = async (options) => {
  const result = await getContractsTable('by-sb-admin', options);
  return result;
};

export const contractsLookupForCompanyAdmin = async (options, companyId) => {
  const result = await getContractsTable(`by-company-admin/${companyId}`, options);
  return result;
};

export const contractsLookupForEmployee = async (options, userId) => {
  const result = await getContractsTable(`by-employee/${userId}`, options);
  return result;
};

export const approveRejectMultipleContractsBySbAdmin = async (props) => {
  const body = {
    contracts: props.contracts,
    action: props.status,
    onBehalfOf: props.onBehalfOf,
  };
  sendGAEvent(GAEventNames.APPROVE_REJECT_MULTIPLE_CONTRACTS_BY_SB_ADMIN, GAEventStages.START, props);
  const data = await axios.post(Domain + '/contract/approve-reject-multiple-contracts-by-sb-admin', body);
  sendGAEvent(GAEventNames.APPROVE_REJECT_MULTIPLE_CONTRACTS_BY_SB_ADMIN, GAEventStages.SUCCESS, props);
  return data?.data;
};

export const sendInvite = async (payload) => {
  sendGAEvent(GAEventNames.SEND_INVITE, GAEventStages.START, payload);
  const data = await axios.post(Domain + '/invitation/send', payload);
  sendGAEvent(GAEventNames.SEND_INVITE, GAEventStages.SUCCESS, payload);
  return data?.data;
};

export const getEmploymentState = async () => {
  const data = await axios.get(Domain + '/contract/get-user-contracts');
  return data?.data;
};

export const getCompanyDetails = async (companyId) => {
  const data = await axios.get(Domain + `/company/${companyId}/details`);
  return data?.data;
};

export const approveOrRejectContractByEmployee = async (payload) => {
  sendGAEvent(GAEventNames.APPROVE_OR_REJECT_CONTRACT_BY_EMPLOYEE, GAEventStages.START, payload);
  const data = await axios.post(Domain + '/contract/approve-or-reject-contract-by-employee', payload);
  sendGAEvent(GAEventNames.APPROVE_OR_REJECT_CONTRACT_BY_EMPLOYEE, GAEventStages.SUCCESS, payload);
  return data?.data;
};

export const createCompanyByAdmin = async (payload) => {
  sendGAEvent(GAEventNames.CREATE_COMPANY_BY_ADMIN, GAEventStages.START, payload);
  const data = await axios.post(Domain + '/company/create-by-sb-admin', payload);
  sendGAEvent(GAEventNames.CREATE_COMPANY_BY_ADMIN, GAEventStages.SUCCESS, payload);
  return data?.data;
};

export const uploadAccreditedInvestorCert = async (payload) => {
  const data = await axios.post(Domain + `/company/${payload.companyId}/upload-accredited-investor-cert`, payload, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  sendGAEvent(GAEventNames.UPLOAD_ACCREDITED_INVESTOR_CERT, GAEventStages.SUCCESS, payload);
  return data?.data;
};

export const updateCompanyBySbAdmin = async (payload) => {
  sendGAEvent(GAEventNames.UPDATE_COMPANY_BY_SB_ADMIN, GAEventStages.START, payload);
  const data = await axios.put(Domain + `/company/${payload.companyId}/update-by-sb-admin/`, payload);
  sendGAEvent(GAEventNames.UPDATE_COMPANY_BY_SB_ADMIN, GAEventStages.SUCCESS, payload);
  return data?.data;
};

export const updateCompanyByCompanyAdmin = async (payload) => {
  sendGAEvent(GAEventNames.UPDATE_COMPANY_BY_COMPANY_ADMIN, GAEventStages.START, payload);
  const data = await axios.put(Domain + `/company/${payload.companyId}/update-by-company-admin/`, payload);
  sendGAEvent(GAEventNames.UPDATE_COMPANY_BY_COMPANY_ADMIN, GAEventStages.SUCCESS, payload);
  return data?.data;
};

//todo: should be a get request
export const getUsersDataForCompanyForm = async (emails) => {
  const data = await axios.post(Domain + '/user/user-data-by-emails', { emails });
  return data?.data;
};

//todo: should be a get request
export const getCompanyDataForRoleCompletion = async (payload) => {
  const data = await axios.post(Domain + '/company/company-names-by-bn-numbers', payload);
  return data?.data;
};

export const getUserDetails = async (userId) => {
  const data = await axios.get(Domain + `/user/${userId}/details`);
  return data?.data;
};

export const getUserRoles = async (options, userId) => {
  const url = buildTableUrl(`/user/${userId}/roles`, options);
  const data = await axios.get(url);
  const result = {
    count: data?.data?.roles.length,
    data: data?.data?.roles,
  };
  return result;
};

export const updateUserByAdmin = async (payload) => {
  const { userId } = payload;
  delete payload.userId;
  sendGAEvent(GAEventNames.UPDATE_USER_BY_ADMIN, GAEventStages.START, payload);
  const data = await axios.put(Domain + `/user/${userId}/update-by-admin`, payload);
  sendGAEvent(GAEventNames.UPDATE_USER_BY_ADMIN, GAEventStages.SUCCESS, payload);
  return data?.data;
};

/**
 * init auth (init-tfa and init-quick-auth) are the beginning of the pin code authentication flows
 * meaning they are the routes that will send the pin code to the user
 * where init-tfa will send to email+mobile and init-quick-auth will send to mobile only
 * they act as a middleware to the login and quick-login routes, but are separated for better readability
 */
export const initPinCodesAuth = async (payload) => {
  sendGAEvent(GAEventNames.INIT_PIN_CODES_VALIDATION, GAEventStages.START, payload); //error and success event logging are handled in the component
  const data = await axios.post(Domain + '/auth/init-tfa', payload);
  sendGAEvent(GAEventNames.INIT_PIN_CODES_VALIDATION, GAEventStages.SUCCESS, payload);
  return data?.data;
};
export const initQuickAuth = async (payload) => {
  const data = await axios.post(Domain + '/auth/init-quick-auth', payload);
  return data.data;
};

/**
 * quick login is the flow of authenticating via mobile only, and it uses a JWT token.
 * first a JWT token is generated and sent to the user's email, via different processes.
 * then the client is calling the init-quick-login route with the token, and the server is decrypting it
 * and returning the email and mobile of the user, and the redirect url.
 * after the client will enter his *matching* personalId, and 2fa pin code, the client will call the quick-login route to authenticate.
 */
export const initQuickLogin = async (payload) => {
  const data = await axios.post(Domain + '/auth/init-quick-login', payload);
  return data.data;
};

export const resendPinCode = async (payload) => {
  sendGAEvent(GAEventNames.RESEND_PIN_CODE, GAEventStages.START, payload);
  const data = await axios.post(Domain + '/auth/resend-pin-code', payload);
  sendGAEvent(GAEventNames.RESEND_PIN_CODE, GAEventStages.SUCCESS, payload);
  return data?.data;
};

export const signUp = async (payload) => {
  sendGAEvent(GAEventNames.SIGN_UP, GAEventStages.START, payload);
  const data = await axios.post(Domain + '/user/sign-up', payload);
  sendGAEvent(GAEventNames.SIGN_UP, GAEventStages.SUCCESS, payload);
  return data?.data;
};

export const createUserByAdmin = async (payload) => {
  sendGAEvent(GAEventNames.CREATE_USER_BY_ADMIN, GAEventStages.START, payload);
  const data = await axios.post(Domain + '/user/create-by-sb-admin', payload);
  sendGAEvent(GAEventNames.CREATE_USER_BY_ADMIN, GAEventStages.SUCCESS, payload);
  return data?.data;
};

export const signAgreement = async () => {
  const data = await axios.post(Domain + '/user/sign-agreement');
  return data?.data;
};

export const getAllUnderwritersTable = async (options) => {
  const url = buildTableUrl('/underwriter/underwriters-lookup', options);
  const data = await axios.get(url);

  const result = {
    count: data?.data?.count,
    data: data?.data?.underwriters,
  };

  return result;
};

export const regenerateUnderwriterSecretKey = async (underwriterId) => {
  const data = await axios.put(Domain + `/underwriter/${underwriterId}/regenerate-secret-key`);
  return data?.data;
};

export const deleteUnderwriterById = async (underwriterId) => {
  const data = await axios.delete(Domain + `/underwriter/${underwriterId}`);
  return data?.data;
};

export const createUnderwriter = async (payload) => {
  const data = await axios.post(Domain + '/underwriter/create', payload);
  return data?.data;
};

export const updateUnderwriter = async (underwriterId, values) => {
  const data = await axios.put(Domain + `/underwriter/${underwriterId}`, { ...values });
  return data?.data;
};

export const getActiveAuctionsTable = async (options) => {
  options.filters = {};
  options.filters['isActive'] = true;
  const url = buildTableUrl('/auction/auctions-lookup', options);
  const data = await axios.get(url);

  const result = {
    count: data?.data?.count,
    data: data?.data?.auctions,
  };

  return result;
};

export const getAuctionOfferStatus = async (auctionId) => {
  const data = await axios.get(Domain + `/auction/${auctionId}/auction-offering-status-of-this-user`);
  return data?.data;
};

export const initiateOffer = async (values) => {
  sendGAEvent(GAEventNames.INITIATE_OFFER, GAEventStages.START, values);
  if (!values.companyId || !values.auctionId) throw new Error('companyId and auctionId are required');
  const data = await axios.post(Domain + `/offer/initiate`, values);
  sendGAEvent(GAEventNames.INITIATE_OFFER, GAEventStages.SUCCESS, values);
  return data?.data;
};

export const initiateOfferEditMode = async (offerId) => {
  if (!offerId) throw new Error('offerId is required');
  sendGAEvent(GAEventNames.INITIATE_OFFER_EDIT_MODE, GAEventStages.START, { offerId });
  const data = await axios.post(Domain + `/offer/launch-edit-mode`, { offerId });
  sendGAEvent(GAEventNames.INITIATE_OFFER_EDIT_MODE, GAEventStages.SUCCESS, { offerId });
  return data?.data;
};

export const cancelOfferEditMode = async (offerId) => {
  if (!offerId) throw new Error('offerId is required');
  sendGAEvent(GAEventNames.CANCEL_OFFER_EDIT_MODE, GAEventStages.START, { offerId });
  const data = await axios.put(Domain + `/offer/cancel-edit-mode`, { offerId });
  sendGAEvent(GAEventNames.CANCEL_OFFER_EDIT_MODE, GAEventStages.SUCCESS, { offerId });
  return data?.data;
};

export const getAuctionById = async (auctionId) => {
  const data = await axios.get(Domain + `/auction/${auctionId}`);
  return data?.data;
};

//time_test: now it is November 2023, currently not in use
/* todo: use in 'edit company' flow */
export const getCompanyBankAccounts = async (companyId) => {
  const data = await axios.get(Domain + `/company/${companyId}/bank-accounts`);
  return data?.data?.bankAccounts;
};

/* todo: implement in 'edit company' flow */
export const addBankAccountToCompany = async (companyId, payload) => {
  sendGAEvent(GAEventNames.ADD_BANK_ACCOUNT_TO_COMPANY, GAEventStages.START, payload);
  const data = await axios.post(Domain + `/company/${companyId}/add-bank-account`, payload);
  sendGAEvent(GAEventNames.ADD_BANK_ACCOUNT_TO_COMPANY, GAEventStages.SUCCESS, payload);
  return data?.data;
};

export const addBankAccountTroughOfferFlow = async (payload) => {
  sendGAEvent(GAEventNames.ADD_BANK_ACCOUNT_TROUGH_OFFER_FLOW, GAEventStages.START, payload);
  const data = await axios.put(Domain + `/offer/add-bank-account-through-offer`, payload);
  sendGAEvent(GAEventNames.ADD_BANK_ACCOUNT_TROUGH_OFFER_FLOW, GAEventStages.SUCCESS, payload);
  return data?.data;
};

export const getDistinctLimitsForAuctionByCompany = async (auctionId, companyId) => {
  const data = await axios.get(Domain + `/offer/distinct-limits-for-auction-by-company?companyId=${companyId}&auctionId=${auctionId}`);
  return data?.data;
};

export const updateOfferScopeAndUnitedAccounts = async (payload) => {
  sendGAEvent(GAEventNames.UPDATE_OFFER_SCOPE_AND_UNITED_ACCOUNTS, GAEventStages.START, payload);
  const data = await axios.put(Domain + `/offer/order-scope-and-united-accounts`, payload);
  sendGAEvent(GAEventNames.UPDATE_OFFER_SCOPE_AND_UNITED_ACCOUNTS, GAEventStages.SUCCESS, payload);
  return data?.data;
};

export const updateOfferScopeAndDividedAccounts = async (payload) => {
  sendGAEvent(GAEventNames.UPDATE_OFFER_SCOPE_AND_DIVIDED_ACCOUNTS, GAEventStages.START, payload);
  const data = await axios.put(Domain + `/offer/order-scope-and-divided-accounts`, payload);
  sendGAEvent(GAEventNames.UPDATE_OFFER_SCOPE_AND_DIVIDED_ACCOUNTS, GAEventStages.SUCCESS, payload);
  return data?.data;
};

export const updateOfferLimitationsAndContacts = async (payload) => {
  sendGAEvent(GAEventNames.UPDATE_OFFER_LIMITATIONS_AND_CONTACTS, GAEventStages.START, payload);
  const data = await axios.put(Domain + `/offer/limitations-and-contacts`, payload);
  sendGAEvent(GAEventNames.UPDATE_OFFER_LIMITATIONS_AND_CONTACTS, GAEventStages.SUCCESS, payload);
  return data?.data;
};

export const getOfferById = async (offerId) => {
  const data = await axios.get(Domain + `/offer/get/${offerId}`);
  return data?.data;
};

export const setSignatories = async (payload) => {
  sendGAEvent(GAEventNames.SET_SIGNATORIES, GAEventStages.START, payload);
  const data = await axios.put(Domain + '/offer/set-signatories', payload);
  sendGAEvent(GAEventNames.SET_SIGNATORIES, GAEventStages.SUCCESS, payload);
  return data?.data;
};

export const setInitiatorReviewNeeded = async (payload) => {
  sendGAEvent(GAEventNames.SET_INITIATOR_REVIEW_NEEDED, GAEventStages.START, payload);
  const data = await axios.put(Domain + '/offer/set-initiator-review-needed', payload);
  sendGAEvent(GAEventNames.SET_INITIATOR_REVIEW_NEEDED, GAEventStages.SUCCESS, payload);
  return data?.data;
};

export const sendOfferToSignatories = async (payload) => {
  sendGAEvent(GAEventNames.SEND_OFFER_TO_SIGNATORIES, GAEventStages.START, payload);
  const data = await axios.put(Domain + '/offer/send-offer-to-signatories', payload);
  sendGAEvent(GAEventNames.SEND_OFFER_TO_SIGNATORIES, GAEventStages.SUCCESS, payload);
  return data?.data;
};

export const signOffer = async (payload) => {
  sendGAEvent(GAEventNames.SIGN_OFFER, GAEventStages.START, payload);
  const data = await axios.put(Domain + '/offer/sign-offer', payload);
  sendGAEvent(GAEventNames.SIGN_OFFER, GAEventStages.SUCCESS, payload);
  return data?.data;
};

export const sendOffer = async (payload) => {
  sendGAEvent(GAEventNames.SEND_OFFER, GAEventStages.START, payload);
  const data = await axios.put(Domain + '/offer/set-offer-status-to-ready', payload);
  sendGAEvent(GAEventNames.SEND_OFFER, GAEventStages.SUCCESS, payload);
  return data?.data;
};

export const getActiveAuctionsAndAssociatedOffers = async () => {
  const data = await axios.get(Domain + '/offer/get-active-auctions-and-associated-offers');
  return data?.data;
};

export const deactivateOffer = async (payload) => {
  sendGAEvent(GAEventNames.DEACTIVATE_OFFER, GAEventStages.START, payload);
  const data = await axios.put(Domain + `/offer/deactivate`, payload);
  sendGAEvent(GAEventNames.DEACTIVATE_OFFER, GAEventStages.SUCCESS, payload);
  return data?.data;
};

export const getUsersCount = async () => {
  const data = await axios.get(Domain + '/user/users-count');
  return data?.data?.count;
};

export const getCompaniesCount = async () => {
  const data = await axios.get(Domain + '/company/companies-count');
  return data?.data?.count;
};

export const getPendingContractsCount = async () => {
  const data = await axios.get(Domain + '/contract/pending-contracts-count');
  return data?.data?.count;
};

/** make sure to clear current access token before sending this request!
 * if access token still in local storage axios will add it to the request (as it should)
 * the the server will try to handle the access token, find it un valid, and will block the request */
export const refreshAccessToken = async (refreshToken) => {
  sendGAEvent(GAEventNames.REFRESH_ACCESS_TOKEN, GAEventStages.START, { refreshToken });
  const data = await axios.post(Domain + '/auth/refresh-access-token', { refreshToken });
  sendGAEvent(GAEventNames.REFRESH_ACCESS_TOKEN, GAEventStages.SUCCESS, { refreshToken });
  return data;
};

/**
 * login and quick-login are the routes that will verify the pin code and return the tokens
 * and essentially end the authentication flow
 */
export const login = async (payload) => {
  sendGAEvent(GAEventNames.LOGIN, GAEventStages.START, payload);
  const data = await axios.post(Domain + '/auth/login', payload);
  sendGAEvent(GAEventNames.LOGIN, GAEventStages.SUCCESS, payload);
  return data?.data;
};

export const quickLogin = async (payload) => {
  const data = await axios.post(Domain + '/auth/quick-login', payload);
  return data.data;
};

export const updateCurrentUser = async (payload) => {
  sendGAEvent(GAEventNames.UPDATE_CURRENT_USER, GAEventStages.START, payload);
  const data = await axios.put(Domain + `/user/update`, payload);
  sendGAEvent(GAEventNames.UPDATE_CURRENT_USER, GAEventStages.SUCCESS, payload);
  return data?.data;
};

export const getOfferDocument = async (offerId) => {
  const data = await axios.get(Domain + `/offer/get-order-document/${offerId}`);
  return data?.data;
};

export const getOffersLookupTable = async (options) => {
  const url = buildTableUrl('/offer/lookup', options);
  const data = await axios.get(url);
  const result = {
    count: data?.data?.count,
    data: data?.data?.offers,
  };

  return result;
};

export const getMinimalOffersLookupTable = async (options) => {
  options.project = ['initiation', '_id', 'status', 'bids'];
  return getOffersLookupTable(options);
};

export const getPublicOfferingsLookupTable = async (options) => {
  const url = buildTableUrl('/public-offerings/lookup', options);
  const data = await axios.get(url);

  const result = {
    count: data?.data?.count,
    data: data?.data?.publicOfferings,
  };

  return result;
};

export const getUpcomingOfferingsLookupTable = async (options) => {
  console.log('getUpcomingOfferingsLookupTable');
  const url = buildTableUrl('/upcoming-offerings/lookup', options);
  const data = await axios.get(url);

  const result = {
    count: data?.data?.count,
    data: data?.data?.upcomingOfferings,
  };

  return result;
};

export const rebuildUpcomingOfferings = async () => {
  const data = await axios.put(Domain + '/upcoming-offerings/rebuild');
  return data?.data;
};

export const getNotifications = async ({ pageParam, limit }) => {
  const data = await axios.get(Domain + `/notifications/get-notifications?page=${pageParam}&pageSize=${limit}`);
  return data?.data;
};

export const getUnreadNotificationsCount = async () => {
  const data = await axios.get(Domain + `/notifications/get-unread-notifications-count`);
  return data?.data?.count;
};

export const markNotificationAsRead = async (notificationId) => {
  sendGAEvent(GAEventNames.MARK_NOTIFICATION_AS_READ, GAEventStages.START, { notificationId });
  const data = await axios.put(Domain + `/notifications/mark-as-read/`, { notificationId });
  sendGAEvent(GAEventNames.MARK_NOTIFICATION_AS_READ, GAEventStages.SUCCESS, { notificationId });
  return data?.data;
};

export const markAllNotificationsAsRead = async () => {
  sendGAEvent(GAEventNames.MARK_ALL_NOTIFICATIONS_AS_READ, GAEventStages.START, {});
  const data = await axios.put(Domain + `/notifications/mark-all-as-read/`);
  sendGAEvent(GAEventNames.MARK_ALL_NOTIFICATIONS_AS_READ, GAEventStages.SUCCESS, {});
  return data?.data;
};

export const getCompanyDividedAccounts = async (options) => {
  const { companyId } = options.queryParams;
  const res = await axios.get(Domain + `/company/${companyId}/divided-accounts`);
  return { data: res?.data?.dividedAccounts, count: res?.data?.count };
};

export const deleteDividedAccount = async ({ companyId, dividedAccountId }) => {
  const res = await axios.delete(Domain + `/company/${companyId}/divided-account/${dividedAccountId}`);
  return res?.data;
};

export const testRefreshToken = async ({ refreshToken }) => {
  const res = await axios.post(Domain + `/auth/test-refresh-token`, { refreshToken });
  return res?.data;
};

export const startQuickLogin = async ({ encryptedEmail, personalId }) => {
  const res = await axios.post(Domain + `/auth/start-quick-login`, { encryptedEmail, personalId });
  return res?.data;
};

export const finishQuickLogin = async ({ encryptedEmailAndSmsPinCode, smsPinCode }) => {
  const res = await axios.post(Domain + `/auth/finish-quick-login`, { encryptedEmailAndSmsPinCode, smsPinCode });
  return res?.data;
};
