import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useCallback, useMemo, useState } from 'react';
import { Alert, Button, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { approveOrRejectContractByEmployee } from 'shared/apis';
import { contractActionTypes } from 'shared/const/contractActionTypes';
import { GAEventNames, GAEventStages } from 'shared/const/googleAnalytics';

import { sendGAEvent } from 'hooks/useGoogleAnalytics';

import ModalConfirm from 'components/UI/ModalConfirm';

const InvitationAlert = ({ contract }) => {
  const [showModal, setShowModal] = useState(false);
  const [action, setAction] = useState(contractActionTypes.APPROVE);
  const { t } = useTranslation();

  const queryClient = useQueryClient();

  const invalidateQueries = useCallback(() => {
    queryClient.invalidateQueries(['contracts']);
    queryClient.invalidateQueries(['getEmploymentState']);
    queryClient.invalidateQueries(['contractsByUserId']);
  }, [queryClient]);

  const {
    mutate: handleInvitation,
    isPending,
    isError,
  } = useMutation({
    mutationKey: 'approveOrRejectContractByEmployee',
    mutationFn: () => approveOrRejectContractByEmployee({ contractId: contract._id, action }),
    onSuccess: () => {
      invalidateQueries();
      sendGAEvent(GAEventNames.APPROVE_OR_REJECT_CONTRACT_BY_EMPLOYEE, GAEventStages.SUCCESS, { contractId: contract._id, action });
    },
  });

  const handleApprove = () => {
    setAction(contractActionTypes.APPROVE);
    setShowModal(true);
  };

  const handleReject = () => {
    setAction(contractActionTypes.REJECT);
    setShowModal(true);
  };

  const handleProceed = () => {
    handleInvitation();
    onHide();
  };

  const onHide = () => {
    setShowModal(false);
  };

  const alertContent = useMemo(() => {
    if (isError) {
      return <div className="text-danger">{t('components.ContractRow.text.error')}</div>;
    }

    if (isPending) {
      return (
        <div className="text-center">
          <Spinner animation="border" />
        </div>
      );
    }

    return (
      <div className="d-flex justify-content-between flex-row align-content-center">
        <p>
          {t('components.ContractRow.text.invitedToJoin')} <strong>{contract.companyName}</strong>.
        </p>
        <div className="d-flex flex-row gap-2">
          <Button className="btn btn-success" onClick={handleApprove} size="sm">
            <FontAwesomeIcon icon={faCheck} />
          </Button>
          <Button className="btn btn-danger" onClick={handleReject} size="sm">
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        </div>
      </div>
    );
  }, [t, contract.companyName, isError, isPending]);

  const modalConfig = useMemo(() => {
    const content =
      action === contractActionTypes.APPROVE
        ? t('components.ContractRow.modal.body.areYouSureAccept')
        : t(`components.ContractRow.modal.body.areYouSureReject`);

    const proceed =
      action === contractActionTypes.APPROVE ? t(`components.ContractRow.modal.actions.approve`) : t(`components.ContractRow.modal.actions.reject`);

    const variant = action === contractActionTypes.APPROVE ? 'success' : 'danger';

    return { content, proceed, variant };
  }, [t, action]);

  return (
    <>
      <Alert variant="warning">{alertContent}</Alert>

      <ModalConfirm
        show={showModal}
        onClose={onHide}
        title={t('components.ContractRow.modal.title')}
        content={
          <>
            {modalConfig.content}
            <strong> {contract.companyName}</strong>
          </>
        }
        proceed={modalConfig.proceed}
        onProceed={handleProceed}
        variant={modalConfig.variant}
        onCancel={onHide}
      />
    </>
  );
};

export default InvitationAlert;
