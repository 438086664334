import { useMutation } from '@tanstack/react-query';
import { ERROR_CODES } from 'errors/context/offer-creation';
import { useCallback, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { AlertContentContext } from 'context/Alert';
import { OfferCreationContext } from 'context/offerCreation';

import { sendOfferToSignatories } from 'shared/apis';
import { ALERT_TYPES } from 'shared/const/alerts';
import { getErrorNameFromErrorResponse } from 'shared/utils/error';
import { errorAlertBuilder } from 'shared/utils/errorAlertBuilder';
import { extractSignatoriesIds } from 'shared/utils/offers';

import { useOfferStateUtils, useSendOfferMutation } from 'hooks/offers';

export const useSignaturesForm = () => {
  const navigate = useNavigate();
  const { offer, setOffer, setOnProceed, setIsLoadingFormSubmit, offerDataLayer } = useContext(OfferCreationContext);
  const { addAlert } = useContext(AlertContentContext);
  const { t } = useTranslation();
  const { isOfferComplete, userCanQuickSend } = useOfferStateUtils({ offer });
  const { mutate: sendOfferMutation, Modal: OfferSendingModal, isPending: sendOfferIsPending } = useSendOfferMutation({ offer });

  /* SUBMIT LOGIC */
  const { mutate: sendOfferToSignatoriesMutation } = useMutation({
    mutationFn: sendOfferToSignatories,
    mutationKey: 'sendOfferToSignatories',
    onSuccess: (data) => {
      setOffer(data);
      addAlert({
        title: t('components.offerSignatures.success.sendToSignatories.title'),
        description: t('components.offerSignatures.success.sendToSignatories.description'),
        type: ALERT_TYPES.SUCCESS,
      });
      navigate(`/user/offers/manage`);
    },
    onError: (error) => {
      const errorName = getErrorNameFromErrorResponse(error);
      let errorAlertContent = null;
      switch (errorName) {
        case ERROR_CODES.OFFER_ALREADY_SENT:
          errorAlertContent = {
            title: t('components.offerSignatures.errors.alreadySent.title'),
            description: t('components.offerSignatures.errors.alreadySent.description'),
          };
          break;
        default:
          errorAlertContent = errorAlertBuilder.bug(error);
      }
      addAlert(errorAlertContent);
    },
  });

  const onSubmit = useCallback(() => {
    if (isOfferComplete) {
      navigate(`/user/offers/create/sending/${offer._id}`);
      return;
    }

    if (!(extractSignatoriesIds(offer)?.length > 0)) {
      addAlert({
        title: t('screens.offerSignatures.errors.offerNoSignatories.title'),
        description: t('screens.offerSignatures.errors.offerNoSignatories.description'),
        type: ALERT_TYPES.ERROR,
      });
      return;
    }

    const payload = {
      offerId: offer._id,
      dataLayer: offerDataLayer,
    };

    userCanQuickSend ? sendOfferMutation(payload) : sendOfferToSignatoriesMutation(payload);
  }, [addAlert, isOfferComplete, navigate, offer, sendOfferMutation, sendOfferToSignatoriesMutation, t, userCanQuickSend, offerDataLayer]);

  useEffect(() => {
    setOnProceed(() => onSubmit);
  }, [setOnProceed, onSubmit]);

  useEffect(() => {
    setIsLoadingFormSubmit(sendOfferIsPending);
  }, [sendOfferIsPending, setIsLoadingFormSubmit]);

  return {
    OfferSendingModal,
  };
};
