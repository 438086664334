import { ErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@tanstack/react-query';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Alert, Button, Container, Form, Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { NotificationManager } from 'react-notifications';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { AlertContentContext } from 'context/Alert';
import { AuthContext } from 'context/Auth';

import { refreshAccessToken, startQuickLogin, testRefreshToken } from 'shared/apis';
import { customLocalStorage } from 'shared/customLocalStorage';
import { quickLoginSchema } from 'shared/schemas/auth';
import { errorAlertBuilder } from 'shared/utils/errorAlertBuilder';

import { useDirection } from 'hooks/useDirection';
import { useWindowSize } from 'hooks/useWindowSize';

import backgroundEn from '../../../asset/login/background-en.svg';
import backgroundHe from '../../../asset/login/background-he.svg';
import smartbullLogo from '../../../asset/logo-online.svg';

import { SmsPinCodeModal } from './SmsPinCodeModal';

export const QuickAuth = () => {
  const { logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const { isDesktop } = useWindowSize();
  const { isRtl } = useDirection();
  const { addAlert } = useContext(AlertContentContext);

  const [isLoggedOut, setIsLoggedOut] = useState(false);
  /* save 'redirectUrl' param from current url */
  const [redirectUrl, setRedirectUrl] = useState();
  /* save 'encryptedEmail' param from current url */
  const [encryptedEmail, setEncryptedEmail] = useState();
  /* we remove tokens to avoid the automatic axios jwt parsing and error handling, so we save the refresh token in order to try to re-login */
  const [refreshToken, setRefreshToken] = useState();
  /* encryptedEmailAndSmsPinCode is the first stage result and is needed for second stage */
  const [encryptedEmailAndSmsPinCode, setEncryptedEmailAndSmsPinCode] = useState();
  const [isLoading, setIsLoading] = useState(true);
  /* we only want to start quick login flow after refresh token is not exist or failed to login */
  const [shouldStartQuickLogin, setShouldStartQuickLogin] = useState(false);
  /* first user inserts personal id, the the pin code modal is showing */
  const [showPinCodeModal, setShowPinCodeModal] = useState(false);
  /* only for resend pin code */
  const [savedPersonalId, setSavedPersonalId] = useState();

  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(quickLoginSchema),
  });
  const {
    formState: { errors },
    register,
    handleSubmit,
    setError,
  } = methods;

  /* SAVE PARAMS AND REFRESH TOKEN, THEN CLEAN LOCAL-STORAGE TOKENS */

  //save then clear tokens
  useEffect(() => {
    if (isLoggedOut) return;
    const currentRefreshToken = customLocalStorage.getRefreshToken();
    if (currentRefreshToken) {
      setRefreshToken(currentRefreshToken);
    }
    logout();
    setIsLoggedOut(true);
  }, [logout, isLoggedOut, setIsLoggedOut]);

  //save url params
  useEffect(() => {
    if (redirectUrl) {
      return;
    }
    setRedirectUrl(searchParams.get('redirectUrl'));
  }, [searchParams, setRedirectUrl, redirectUrl]);

  useEffect(() => {
    if (encryptedEmail) {
      return;
    }
    setEncryptedEmail(searchParams.get('encryptedEmail'));
  }, [searchParams, setEncryptedEmail, encryptedEmail]);

  /* TRY TO RE-LOGIN WITH REFRESH TOKEN */

  const tryLoginWithRefreshToken = async () => {
    await refreshAccessToken(refreshToken);
    navigate(redirectUrl);
  };

  const { mutate: testRefreshTokenMutation } = useMutation({
    mutationKey: 'testRefreshToken',
    mutationFn: testRefreshToken,
    onSuccess: (data) => {
      if (data.isRefreshTokenValid) {
        tryLoginWithRefreshToken();
      } else {
        setShouldStartQuickLogin(true);
      }
    },
    onError: (error) => {
      NotificationManager.error(t('screens.quickAuth.login'));
    },
  });

  useEffect(() => {
    if (!isLoggedOut) {
      return;
    }
    if (refreshToken) {
      testRefreshTokenMutation({ refreshToken });
    } else {
      setShouldStartQuickLogin(true);
    }
  }, [isLoggedOut, refreshToken]);

  /* QUICK LOGIN FLOW */

  useEffect(() => {
    if (!shouldStartQuickLogin) {
      return;
    }
    setIsLoading(false);
  }, [shouldStartQuickLogin, setIsLoading]);

  const startQuickLoginErrorHandling = useCallback(
    (error) => {
      const errorName = error.response?.data?.error?.name;
      switch (errorName) {
        case 'ID_NOT_MATCH':
          setError('personalId', { type: 'manual', message: t('screens.authentication.errors.ID_NOT_MATCH') }, { shouldFocus: true });
          break;
        default:
          addAlert(errorAlertBuilder.bug(error));
      }
    },
    [setError, addAlert, t],
  );

  const { mutate: startQuickLoginMutation, isPending: startQuickLoginIsPending } = useMutation({
    mutationKey: 'startQuickLogin',
    mutationFn: startQuickLogin,
    onSuccess: (data) => {
      const { encryptedEmailAndPinCode } = data;
      setShowPinCodeModal(true);
      setEncryptedEmailAndSmsPinCode(encryptedEmailAndPinCode);
    },
    onError: (error) => {
      startQuickLoginErrorHandling(error);
    },
  });

  const onSubmitPersonalId = (data) => {
    const { personalId } = data;
    setSavedPersonalId(personalId);
    startQuickLoginMutation({ encryptedEmail, personalId });
  };

  const resendPinCode = () => {
    startQuickLoginMutation({ encryptedEmail, personalId: savedPersonalId });
  };

  /* JSX */

  if (isLoading) return <Spinner animation="border" />;

  return (
    <>
      <Container className="h-full d-flex flex-col pt-3 md:max-w-[95vw]">
        <img src={smartbullLogo} id="image" className="w-[166px] md:w-[400px] cursor-pointer my-4" alt="" onClick={() => navigate('/')} />

        <Form onSubmit={handleSubmit(onSubmitPersonalId)} noValidate className="mt-2 max-w-[480px]">
          <h4>{t('screens.authentication.welcomeText')}</h4>

          <p className="text-muted">{t('screens.authentication.descriptionText')}</p>

          <ErrorMessage errors={errors} name="general" render={({ message }) => <Alert variant="danger">{message}</Alert>} />

          <div>
            <Form.Group className="mb-4">
              <Form.Label>{t('screens.authentication.labels.id')}</Form.Label>
              <Form.Control {...register('personalId')} required isInvalid={errors.personalId} style={{ direction: 'ltr' }} pattern="\d*" />
              <ErrorMessage
                errors={errors}
                name="personalId"
                render={({ message }) => <Form.Control.Feedback type="invalid">{t(message)}</Form.Control.Feedback>}
              />
            </Form.Group>
          </div>

          <Button variant="primary" type="submit">
            {t('screens.authentication.actions.joinOrSignIn')}
          </Button>
        </Form>
      </Container>
      <SmsPinCodeModal
        show={showPinCodeModal}
        redirectUrl={redirectUrl}
        encryptedEmailAndSmsPinCode={encryptedEmailAndSmsPinCode}
        onClose={() => {
          setShowPinCodeModal(false);
        }}
        resendPinCode={resendPinCode}
        isResending={startQuickLoginIsPending}
      />
      {isDesktop && (
        <div className="w-full h-full position-absolute top-0 end-0 overflow-hidden -z-50">
          <img src={isRtl ? backgroundHe : backgroundEn} className="position-absolute top-0 end-[0] h-full  z-[-9999]" alt="decorative ellipse" />
        </div>
      )}
    </>
  );
};
