import { useTranslation } from 'react-i18next';

import { getPublicOfferingsLookupTable } from 'shared/apis';

import { TableQuery } from 'components/UI/TableQuery';

import { columns } from './columns';

export const PublicOfferingsTable = (props) => {
  const { t } = useTranslation();

  return (
    <TableQuery
      queryKey="latest-public-offerings"
      queryFn={getPublicOfferingsLookupTable}
      defaultSort="auctionDate"
      Columns={columns}
      columnTPrefix="screens.dashboard.publicOfferings.columns."
      title={t('screens.dashboard.titles.activeTenders')}
      enabled={true}
      {...props}
    />
  );
};
