import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faActivity, faBellRingOutline, faCalendarMonthOutline, faEnvelopePaper } from 'asset/icons';
import some from 'lodash/some';
import { useContext } from 'react';
import { Alert, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { AuthContext } from 'context/Auth';

import { AuctionsTable } from 'components/AuctionsTable/AuctionTable';
import { PastOffersTable } from 'components/Offers/PastOffersTable';
import { PublicOfferingsTable } from 'components/PublicOfferingsTable';
import Box from 'components/UI/Box';
import { UpcomingOfferingsTable } from 'components/UpcomingOfferingsTable';
import ContractRow from 'components/User/Dashboard/ContractsRow';

const DashboardScreen = () => {
  const { t } = useTranslation();
  const { firstName, lastName, activeContracts, signedCurrentAgreementVersion } = useContext(AuthContext);

  const BoxLink = ({ title, link }) => (
    <Link className="underline self-end font-semibold" to={link}>
      {title}
    </Link>
  );

  const publicOfferingsLink = <BoxLink title={t('screens.dashboard.labels.allPublicOfferings')} link="/user/public-offerings" />;
  const pastOffersLink = <BoxLink title={t('screens.dashboard.labels.allOffers')} link="/user/offers/manage" />;
  const upcomingOfferingsLink = <BoxLink title={t('screens.dashboard.labels.allUpcomingOfferings')} link="/user/upcoming-offerings" />;

  return (
    <Container className="d-flex flex-col gap-2 my-4">
      <Row style={{ textAlign: 'unset' }}>
        <h1>{t('screens.dashboard.labels.hello') + ' ' + firstName + ' ' + lastName}</h1>
      </Row>
      <Row>{signedCurrentAgreementVersion && <ContractRow />}</Row>
      {!some(activeContracts) && (
        <Row>
          <Alert variant="info">
            <p className="text-lg">{t('screens.dashboard.labels.welcome')}</p>
            <p>{t('screens.dashboard.labels.noActiveContracts')}</p>
          </Alert>
        </Row>
      )}
      <Row className="mb-4">
        <Box
          title={
            <>
              <FontAwesomeIcon icon={faBellRingOutline} className="mb-[-2px]" /> {t('screens.dashboard.titles.activeTenders')}
            </>
          }
          headerClassName="px-[12px]"
          className="!bg-[#DBEBEB]"
        >
          <AuctionsTable status="active" />
        </Box>
      </Row>
      <Row className="mb-4">
        <Box
          title={
            <>
              <FontAwesomeIcon icon={faCalendarMonthOutline} /> {t('screens.dashboard.titles.upcomingOfferings')}
            </>
          }
          extraHeaderContent={upcomingOfferingsLink}
          headerClassName="justify-between px-[12px]"
          className="!bg-[#ECF8F9]"
        >
          <UpcomingOfferingsTable defaultLimit={100} limitedHeight={true} withSearch={false} withPagination={false} isExtended={false} />
        </Box>
      </Row>
      <Row className="mb-4">
        <Box
          title={
            <>
              <FontAwesomeIcon icon={faEnvelopePaper} /> {t('screens.dashboard.titles.lastOffers')}
            </>
          }
          extraHeaderContent={pastOffersLink}
          headerClassName="justify-between px-[12px]"
          className="!bg-[#F6FBFC]"
        >
          <PastOffersTable withSort={false} withSearch={false} withPagination={false} defaultLimit={4} />
        </Box>
      </Row>
      <Row className="mb-4">
        <Box
          title={
            <>
              <FontAwesomeIcon icon={faActivity} /> {t('screens.dashboard.titles.publicOfferings')}
            </>
          }
          extraHeaderContent={publicOfferingsLink}
          headerClassName="justify-between px-[12px]"
          className="!bg-[#FCFEFF]"
        >
          <PublicOfferingsTable defaultLimit={6} scrollable withSort={false} withSearch={false} withPagination={false} />
        </Box>
      </Row>
    </Container>
  );
};

export default DashboardScreen;
