export const detailedStatuses = {
  PENDING_ON_COMPANY: 'PENDING_ON_COMPANY',
  PENDING_ON_EMPLOYEE: 'PENDING_ON_EMPLOYEE',
  ACTIVE: 'ACTIVE',
  REJECTED: 'REJECTED',
};

export const serverDetailedStatuses = {
  ACTIVE: 'ACTIVE',
  WAITING_FOR_USER_APPROVAL: 'WAITING_FOR_USER_APPROVAL',
  WAITING_FOR_COMPANY_APPROVAL: 'WAITING_FOR_COMPANY_APPROVAL',
  WAITING_FOR_COMPANY_AND_USER_APPROVAL: 'WAITING_FOR_COMPANY_AND_USER_APPROVAL',
  REJECTED_BY_COMPANY: 'REJECTED_BY_COMPANY',
  REJECTED_BY_USER: 'REJECTED_BY_USER',
};
