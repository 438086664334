import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const ContractRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem 1.5rem;
  justify-content: start;
  align-items: center;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    gap: 1rem;
    flex-direction: column;
    align-items: start;
    justify-content: center;
  }
`;

export const WarningText = styled.span`
  color: #f59a23;
  background-color: #fdeeda;
  font-size: 1.1rem;
  padding: 0 0.5rem;
  font-weight: 600;
`;

export const ContractItemInner = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5rem;
  justify-content: start;
  align-items: center;
`;

export const CompanyName = styled(Link)`
  font-size: 1.2rem;
  font-weight: 400;
  color: rgb(100, 100, 100);
  text-decoration: none;
`;
