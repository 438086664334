import compact from 'lodash/compact';

import { orderedDividedAccountsTableHeaders } from 'shared/const/dividedAccountsTable';

// TSV (Tab Separated Values) is a format for tabular data in a text file.
// Each line in a TSV file represents a row in the table, and columns are separated by a tab character.
// TSV files are often used for moving tabular data between two different computer programs that natively operate on incompatible (often proprietary or undocumented) formats.
// And is the default format for Microsoft Excel and Apple Numbers for Mac.a
export const tsvToJson = (tsvText) => {
  // Split all the text into seperate lines on new lines and carriage return feeds
  let allTextLines = tsvText.split(/\r\n|\n/);

  // Filter lines that are empty after removing tabs and spaces
  allTextLines = allTextLines.filter((line) => !!line.replace(/[\t\s]/g, ''));

  // Split per line on tabs and commas
  let headers = allTextLines[0].split(/\t|,/);
  let lines = [];

  for (let i = 1; i < allTextLines.length; i++) {
    let data = allTextLines[i].split(/\t|,/);

    if (data.length === headers.length) {
      let row = {};
      for (let j = 0; j < headers.length; j++) {
        row[headers[j]] = data[j];
      }
      lines.push(row);
    }
  }

  return lines;
};

export const isTsv = (content) => {
  // Split the content into lines
  let lines = content.split('\n');

  // compact the lines array by removing empty lines
  lines = compact(lines);

  // Check if each line contains at least one tab character
  for (let line of lines) {
    if (!line.includes('\t')) {
      return false; // If any line doesn't have a tab, it's not TSV
    }
  }

  return true; // All lines have tabs, so it's likely TSV
};

export const buildNewTableLine = () => {
  /* create an object with all the headers as keys and empty strings as values */
  return orderedDividedAccountsTableHeaders.reduce((acc, curr) => ({ ...acc, [curr]: '' }), {});
};
