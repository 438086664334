import { useTranslation } from 'react-i18next';

import { getUpcomingOfferingsLookupTable } from 'shared/apis';

import { TableQuery } from 'components/UI/TableQuery';

import { columns } from './columns';

export const UpcomingOfferingsTable = ({ isExtended = true, ...props }) => {
  const { t } = useTranslation();

  return (
    <TableQuery
      queryKey="upcoming-offerings"
      queryFn={getUpcomingOfferingsLookupTable}
      defaultSort="issuingCompany"
      Columns={columns(isExtended)}
      columnTPrefix="screens.dashboard.upcomingOfferings.columns."
      title={t('screens.dashboard.titles.upcomingOfferings')}
      enabled={true}
      {...props}
    />
  );
};
