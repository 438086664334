import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faActivity, faCalendarMonthOutline } from 'asset/icons';
import { useContext } from 'react';
import { Container, Nav } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { AuthContext } from 'context/Auth';

import { systemRoles } from 'shared/const/systemRoles';

import { useAdminStats } from 'hooks/useAdminStats';

import NavLink from 'components/UI/Layout/NavLink';
import { StyledNavbar as Navbar } from 'components/UI/Layout/styles';
import { NotificationsIcon } from 'components/UI/Notifications';

import Logo from 'asset/logo-online.svg';
import homeIcon from 'asset/navigation/Home.svg';
import paperIcon from 'asset/navigation/Paper.svg';

import { UserDropdown } from './UserDropDown';

export const NavigationBar = () => {
  const { systemRole, activeContracts } = useContext(AuthContext);

  const navigate = useNavigate();
  // eyal_todo: move to a auth service/ hook
  const isAdmin = systemRole === systemRoles.SB_ADMIN;
  const isUser = systemRole === systemRoles.USER;

  const { t } = useTranslation();

  const {
    usersCount,
    usersCountLoading,
    usersCountError,
    companiesCount,
    companiesCountLoading,
    companiesCountError,
    pendingContractsCount,
    pendingContractsCountLoading,
    pendingContractsCountError,
  } = useAdminStats({
    enabled: isAdmin,
  });

  const commonMenu = [];

  const getAdminTabLabel = ({ label, counter, isLoading, isError }) => {
    if (!counter) {
      return label;
    }

    if (isLoading) {
      return `${label} (...)`;
    }
    if (isError) {
      return `${label} (error)`;
    }
    return `${label} (${counter})`;
  };

  const adminMenu = [
    ...commonMenu,
    <NavLink
      linkTo="/admin/users/table"
      label={getAdminTabLabel({
        label: t('components.NavigationBar.users'),
        counter: usersCount,
        isLoading: usersCountLoading,
        isError: usersCountError,
      })}
      comingSoon={null}
      isLocked={false}
      key={'/admin/users/table'}
    />,
    <NavLink
      linkTo="/admin/companies/table"
      label={getAdminTabLabel({
        label: t('components.NavigationBar.companies'),
        counter: companiesCount,
        isLoading: companiesCountLoading,
        isError: companiesCountError,
      })}
      comingSoon={null}
      isLocked={false}
      key={'/admin/companies'}
    />,
    <NavLink
      linkTo="/admin/contracts"
      label={getAdminTabLabel({
        label: t('components.NavigationBar.contracts'),
        counter: pendingContractsCount,
        isLoading: pendingContractsCountLoading,
        isError: pendingContractsCountError,
      })}
      comingSoon={null}
      isLocked={false}
      key={'/admin/contracts'}
    />,
    <NavLink
      linkTo="/admin/underwriters"
      label={t('components.NavigationBar.underwriters')}
      comingSoon={null}
      isLocked={false}
      key={'/admin/underwriters'}
    />,
    <NavLink linkTo="/admin/auctions" label={t('components.NavigationBar.auctions')} comingSoon={null} isLocked={false} key={'/admin/auctions'} />,
    <NavLink linkTo="/admin/data" label={t('components.NavigationBar.data')} comingSoon={null} isLocked={false} key={'/admin/data'} />,
  ];

  const userMenu = [
    ...commonMenu,
    <NavLink
      linkTo="/user"
      label={
        <div className="d-inline-flex gap-2 align-items-center">
          <img src={homeIcon} alt="house" />
          {t('components.NavigationBar.home')}
        </div>
      }
      comingSoon={null}
      isLocked={false}
      key={'/'}
    />,
    <NavLink
      linkTo="/user/offers/manage"
      label={
        <div className="d-inline-flex gap-2 align-items-center">
          <img src={paperIcon} alt="document" />
          {t('components.NavigationBar.myOffers')}
        </div>
      }
      key={'/offers/manage'}
    />,
    <NavLink
      linkTo="/user/public-offerings"
      label={
        <div className="d-inline-flex gap-2 align-items-center">
          <FontAwesomeIcon icon={faActivity} size="xl" />
          {t('components.NavigationBar.publicOfferings')}
        </div>
      }
      key={'/public-offerings'}
    />,
    <NavLink
      linkTo="/user/upcoming-offerings"
      label={
        <div className="d-inline-flex gap-2 align-items-center">
          <FontAwesomeIcon icon={faCalendarMonthOutline} size="xl" />
          {t('components.NavigationBar.upcomingOfferings')}
        </div>
      }
      key={'/upcoming-offerings'}
    />,
  ];

  const renderMenu = () => {
    if (isAdmin) {
      return adminMenu;
    } else if (isUser && activeContracts.length > 0) {
      return userMenu;
    } else {
      return commonMenu;
    }
  };

  return (
    <>
      <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" style={{ padding: '1rem' }}>
        <Container>
          <Navbar.Brand
            onClick={() => {
              navigate('/');
            }}
          >
            <div className="w-[180px]">
              <img src={Logo} className="w-[100%] cursor-pointer" alt="logo" />
            </div>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="d-flex align-items-center gap-4 ms-5">{renderMenu()}</Nav>
            <Nav style={{ marginInlineStart: 'auto' }}>
              <UserDropdown />
              <NotificationsIcon />
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};
