import { detailedStatuses } from 'shared/const/detailedStatuses';

import { TableQuery } from 'components/UI/TableQuery';

import { approvedColumns as Columns } from './columns';

const ApprovedTable = ({ queryFn }) => {
  return (
    <TableQuery
      queryKey={'contractsLookup'}
      queryFn={queryFn}
      Columns={Columns}
      columnTPrefix={'screens.contracts.table.columns.'}
      defaultSort={'employee.firstName'}
      queryParams={{ detailedStatus: detailedStatuses.ACTIVE }}
      withExport={true}
    />
  );
};

export default ApprovedTable;
