import dayjs from 'dayjs';
import compact from 'lodash/compact';
import some from 'lodash/some';
import numbro from 'numbro';

import { CommonLink } from 'components/common/CommonLink';

import { WinningOffersCell } from './winningOffers/WinningOffersCell';

// ensure empty values are not displayed
const wrapper = (value, formatCb) => {
  return value ? formatCb(value) : '';
};

export const columns = compact([
  {
    Header: 'securityName',
    accessor: 'securityName',
  },
  {
    Header: 'securityId',
    accessor: 'securityId',
  },
  {
    Header: 'mosadiAuctionDate',
    accessor: 'stages.mosadi.auctionDate',
    Cell: (row) => wrapper(row.stages.mosadi.auctionDate, (value) => dayjs(value).format('DD.MM.YYYY')),
  },
  {
    Header: 'auctionDate',
    accessor: 'auctionDate',
    Cell: (row) => wrapper(row.auctionDate, (value) => dayjs(value).format('DD.MM.YYYY')),
  },
  {
    Header: 'winningOffers',
    accessor: 'winningOffers',
    noSort: true,
    Cell: (row) => {
      const { winningOffers } = row;
      if (!some(winningOffers)) {
        return '';
      }

      return <WinningOffersCell publicOffering={row} />;
    },
  },
  {
    Header: 'sector',
    accessor: 'sector',
  },
  {
    Header: 'linkage',
    accessor: 'linkage',
  },
  {
    Header: 'shelfLink',
    accessor: 'shelfLink',
    Cell: (row) => {
      const link = row.shelfLink;
      return <CommonLink link={link} />;
    },
  },
  {
    Header: 'duration',
    accessor: 'duration',

    Cell: (row) => wrapper(row.duration, (value) => numbro(value).format('0,0')),
  },
  {
    Header: 'mosadiClosingLimit',
    accessor: 'stages.mosadi.closing.limit',

    Cell: (row) => wrapper(row.stages.mosadi.closing.limit, (value) => numbro(value).format('0,0')),
  },
  {
    Header: 'mosadiClosingYield',
    accessor: 'stages.mosadi.closing.yield',

    // dont need to divide by 100 because its already in percentage
    Cell: (row) => wrapper(row.stages.mosadi.closing.yield, (value) => numbro(value).format('0.00%')),
  },
  {
    Header: 'mosadiClosingMargin',
    accessor: 'stages.mosadi.closing.margin',

    // dont need to divide by 100 because its already in percentage
    Cell: (row) => wrapper(row.stages.mosadi.closing.margin, (value) => numbro(value).format('0.00%')),
  },
  {
    Header: 'commitmentFee',
    accessor: 'stages.mosadi.commitmentFee',
    Cell: (row) => wrapper(row.stages.mosadi.commitmentFee, (value) => numbro(value / 100).format('0.00%')),
  },
  {
    Header: 'mosadiOpeningLimit',
    accessor: 'stages.mosadi.opening.limit',

    Cell: (row) => wrapper(row.stages.mosadi.opening.limit, (value) => numbro(value).format('0,0')),
  },
  {
    Header: 'mosadiOpeningYield',
    accessor: 'stages.mosadi.opening.yield',

    // dont need to divide by 100 because its already in percentage
    Cell: (row) => wrapper(row.stages.mosadi.opening.yield, (value) => numbro(value).format('0.00%')),
  },
  {
    Header: 'mosadiOpeningMargin',
    accessor: 'stages.mosadi.opening.margin',

    // dont need to divide by 100 because its already in percentage
    Cell: (row) => wrapper(row.stages.mosadi.opening.margin, (value) => numbro(value).format('0.00%')),
  },
  {
    Header: 'mosadiPriceDemand',
    accessor: 'stages.mosadi.demand.price',

    Cell: (row) => wrapper(row.stages.mosadi.demand.price, (value) => numbro(value).format('0,0$')),
  },
  {
    Header: 'mosadiUnitsDemand',
    accessor: 'stages.mosadi.demand.units',

    Cell: (row) => wrapper(row.stages.mosadi.demand.units, (value) => numbro(value).format('0,0')),
  },
  {
    Header: 'mosadiClosingPrice',
    accessor: 'stages.mosadi.closing.price',

    Cell: (row) => wrapper(row.stages.mosadi.closing.price, (value) => numbro(value).format('0,0$')),
  },
  {
    Header: 'mosadiClosingUnits',
    accessor: 'stages.mosadi.closing.units',

    Cell: (row) => wrapper(row.stages.mosadi.closing.units, (value) => numbro(value).format('0,0')),
  },
  {
    Header: 'publicAuctionDate',
    accessor: 'stages.public.auctionDate',
    Cell: (row) => wrapper(row.stages.public.auctionDate, (value) => dayjs(value).format('DD.MM.YYYY')),
  },
  {
    Header: 'publicOfferedUnits',
    accessor: 'stages.public.offeredUnits',

    Cell: (row) => wrapper(row.stages.public.offeredUnits, (value) => numbro(value).format('0,0')),
  },
  {
    Header: 'publicClosingUnits',
    accessor: 'stages.public.closing.units',

    Cell: (row) => wrapper(row.stages.public.closing.units, (value) => numbro(value).format('0,0')),
  },
  {
    Header: 'publicClosingLimit',
    accessor: 'stages.public.closing.limit',

    Cell: (row) => wrapper(row.stages.public.closing.limit, (value) => numbro(value).format('0,0')),
  },
  {
    Header: 'publicClosingPrice',
    accessor: 'stages.public.closing.price',

    Cell: (row) => wrapper(row.stages.public.closing.price, (value) => numbro(value).format('0,0$')),
  },
  {
    Header: 'leadUnderwriter',
    accessor: 'leadUnderwriter',
    noSort: true,
  },
  {
    Header: 'subUnderwriters',
    accessor: 'subUnderwriters',
    noSort: true,
  },
  {
    Header: 'advisors',
    accessor: 'advisors',
    noSort: true,
  },
  {
    Header: 'advisorFee',
    accessor: 'advisorFee',

    Cell: (row) => wrapper(row.advisorFee, (value) => numbro(value / 100).format('0.00%')),
  },
  {
    Header: 'distributionFee',
    accessor: 'distributionFee',

    Cell: (row) => wrapper(row.distributionFee, (value) => numbro(value / 100).format('0.00%')),
  },
  {
    Header: 'successFee',
    accessor: 'successFee',

    Cell: (row) => wrapper(row.successFee, (value) => numbro(value / 100).format('0.00%')),
  },
  {
    Header: 'underwritingFee',
    accessor: 'underwritingFee',

    Cell: (row) => wrapper(row.underwritingFee, (value) => numbro(value / 100).format('0.00%')),
  },
  {
    Header: 'auctionFee',
    accessor: 'stages.public.auctionFee',

    Cell: (row) => wrapper(row.stages.public.auctionFee, (value) => numbro(value).format('0,0$')),
  },
  {
    Header: 'auctionType',
    accessor: 'auctionType',
    noSort: true,
  },
  {
    Header: 'issuer',
    accessor: 'issuer',
  },
  {
    Header: 'offeringType',
    accessor: 'offeringType',
    noSort: true,
  },
  {
    Header: 'rating',
    accessor: 'rating',
    noSort: true,
  },
  {
    Header: 'covenant',
    accessor: 'covenant',
    noSort: true,
  },
  {
    Header: 'notes',
    accessor: 'notes',
    noSort: true,
  },
]);
