import { contractStatues } from 'shared/const/contractStatues';

import phCheck from 'asset/dashboard/ph_check.svg';
import phClock from 'asset/dashboard/ph_clock.svg';

import * as S from './styles';

const ContractItem = ({ contract }) => {
  if (![contractStatues.ACTIVE, contractStatues.WAITING_FOR_COMPANY_APPROVAL].includes(contract.status)) {
    return null;
  }

  return (
    <S.ContractItemInner>
      {contract.status === contractStatues.ACTIVE && <img src={phCheck} alt="check" />}
      {contract.status === contractStatues.WAITING_FOR_COMPANY_APPROVAL && <img src={phClock} alt="clock" />}
      <S.CompanyName to={`/user/companies/${contract.companyId}`}>{contract.companyName}</S.CompanyName>
    </S.ContractItemInner>
  );
};

export default ContractItem;
