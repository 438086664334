import { getOffersLookupTable } from 'shared/apis';

import { TableQuery } from 'components/UI/TableQuery';

import { useOfferTableColumns } from './columns';

export const PastOffersTable = ({ ...props }) => {
  const Columns = useOfferTableColumns();

  return (
    <>
      <TableQuery
        queryKey={'getUserOffersLookupTable'}
        queryFn={getOffersLookupTable}
        Columns={Columns}
        columnTPrefix={'screens.offers.table.columns.'}
        defaultSort={'initiation.auctionDetails.closeTime'}
        {...props}
      />
    </>
  );
};
